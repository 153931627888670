import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import Container from '../../components/UI/Container';

import RelatedProductsWrapper, {
  GridContainer,
  ProductItemWrapper,
  SingleProductWrapper
} from './related.style';

const RelatedProducts = ({ heading, subtitle, related = [] }) => {
  if (related.length < 1) {
    return null;
  }
  return (
    <RelatedProductsWrapper>
      <Container>
        <GridContainer template="auto">
          <div className="inner-heading">
            <h2>{heading}</h2>
            <p>{subtitle}</p>
          </div>
        </GridContainer>
        {related.length === 1 && (
          <GridContainer template="auto">
            {related.map(product => {
              const {
                excerpt,
                frontmatter: { slug, thumbnail, title, tags = [] }
              } = product;
              return (
                <SingleProductWrapper key={`sp-product-${slug}`}>
                  <div className="product-img">
                    <Link to={`/product/${slug}`}>
                      <Image src={thumbnail} alt={`${title} from UpSolar`} />
                    </Link>
                  </div>
                  <div className="product-info-group">
                    <div className="product-tags">
                      {tags.map((tag, index) => {
                        const isLast = index === tags.length - 1;
                        return (
                          <Fragment key={`${slug}-${tag}`}>
                            <span>{tag}</span>
                            {!isLast && <span className="divider">|</span>}
                          </Fragment>
                        );
                      })}
                    </div>
                    <div className="product-info">
                      <Link to={`/product/${slug}`}>
                        <h3>{title}</h3>
                        <p>{excerpt}</p>
                      </Link>
                    </div>
                  </div>
                </SingleProductWrapper>
              );
            })}
          </GridContainer>
        )}
        {related.length > 1 && (
          <GridContainer template="1fr">
            {related.map(product => {
              const {
                excerpt,
                frontmatter: { slug, thumbnail, title, tags = [] }
              } = product;
              return (
                <ProductItemWrapper key={`sp-product-${slug}`}>
                  <div className="product-img">
                    <Link to={`/product/${slug}`}>
                      <Image src={thumbnail} alt={`${title} from UpSolar`} />
                    </Link>
                  </div>
                  <div className="product-info-group">
                    <div className="product-tags">
                      {tags.map((tag, index) => {
                        const isLast = index === tags.length - 1;
                        return (
                          <Fragment key={`${slug}-${tag}`}>
                            <span>{tag}</span>
                            {!isLast && <span className="divider">|</span>}
                          </Fragment>
                        );
                      })}
                    </div>
                    <div className="product-info">
                      <Link to={`/product/${slug}`}>
                        <h3>{title}</h3>
                        <p>{excerpt}</p>
                      </Link>
                    </div>
                  </div>
                </ProductItemWrapper>
              );
            })}
          </GridContainer>
        )}
      </Container>
    </RelatedProductsWrapper>
  );
};

export default RelatedProducts;
