import React from 'react';
import { Link } from 'gatsby';
import Button from 'reusecore/src/elements/Button';
import Container from '../../components/UI/Container';
import RelatedListWrapper, { GridContainer, SolutionWrapper } from './related-list.style';

const FooterRelatedList = ({ prevPost, nextPost, heading, subtitle, prefix }) => {
  return (
    <RelatedListWrapper>
      <Container>
        <GridContainer template="70% auto">
          <div className="inner-heading">
            {heading && <h2>{heading}</h2>}
            {subtitle && <p>{subtitle}</p>}
          </div>
          {prefix && (
            <div className="inner-nav">
              <Link to={`/${prefix}`}>
                Ver más
                {' '}
                <i className="flaticon-next" />
              </Link>
            </div>
          )}
        </GridContainer>
        <GridContainer template="1fr 1fr">
          <SolutionWrapper
            featured
            cover={prevPost.frontmatter.cover}
            key={`${prevPost.frontmatter.slug}-${prevPost.frontmatter.title}`}
          >
            <div className="product-info">
              <Link to={`/soluciones/${prevPost.frontmatter.slug}`}>
                <h3>{prevPost.frontmatter.title}</h3>
              </Link>

              <p>{prevPost.excerpt}</p>
            </div>
            <div className="product-nav">
              <Link to={`/soluciones/${prevPost.frontmatter.slug}`}>
                <Button title="Ver Más" variant="outlined" />
              </Link>
            </div>
          </SolutionWrapper>
          <SolutionWrapper
            featured
            cover={nextPost.frontmatter.cover}
            key={`${nextPost.frontmatter.slug}-${nextPost.frontmatter.title}`}
          >
            <div className="product-info">
              <Link to={`/soluciones/${nextPost.frontmatter.slug}`}>
                <h3>{nextPost.frontmatter.title}</h3>
              </Link>
              <p>{nextPost.excerpt}</p>
            </div>
            <div className="product-nav">
              <Link to={`/soluciones/${nextPost.frontmatter.slug}`}>
                <Button title="Ver Más" variant="outlined" />
              </Link>
            </div>
          </SolutionWrapper>
        </GridContainer>
        <GridContainer />
      </Container>
    </RelatedListWrapper>
  );
};

export default FooterRelatedList;
