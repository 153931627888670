import styled, { css } from 'styled-components';

const RelatedProductsWrapper = styled.div`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  .inner-heading {
    h2 {
      color: ${({ theme }) => theme.colors.headingColor};
      font-size: 30px;
      line-height: 35px;
      font-weight: 900;
      margin: 0;
      margin-bottom: 5px;
    }
    p {
      color: ${({ theme }) => theme.colors.labelColor};
      margin-bottom: 10px;
      font-size: 1rem;
      line-height: 20px;
      font-weight: 300;
    }
  }
  .inner-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media only screen and (max-width: 667px) {
      justify-content: inherit;
    }
    a {
      color: ${({ theme }) => theme.colors.headingColor};
      text-decoration: none;
      font-weight: 900;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const ProductItemWrapper = styled.div`
  display: grid;
  min-height: 25vh;
  z-index: 1;
  ${'' /* border-radius: 3px; */}
  ${'' /* border-bottom: 3px solid ${({ theme }) => theme.colors.inactiveIcon}; */}
  ${'' /* background-color: ${({ theme }) => theme.colors.background}; */}
  transition: 0.2s all;
  ${({ featured }) =>
    featured &&
    css`
      background-color: transparent;
      padding: 0;
      @media only screen and (min-width: 668px) and (max-width: 980px) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    `}
  @media only screen and (max-width: 667px) {
    margin-top: 20px;
    padding: 0 1rem;
    margin: 1rem 1rem;
  }
  @media only screen and (max-width: 572px) {
    margin-top: 20px;
    padding: 0 0rem;
    margin: 1rem 0rem;
  }
  .product-img {
    img {
      width: 100%;
      height: 100%;
      border-radius: 3px;
      object-fit: contain;
      height: 325px;
      background-color: #fff;
    }
    @media only screen and (max-width: 991px) {
      img {
        height: 300px;
      }
    }
    @media only screen and (max-width: 667px) {
      width: 100%;
    }
  }
  .product-info-group {
    background-color: ${({ theme }) => theme.colors.background};
    border-bottom: 3px solid ${({ theme }) => theme.colors.inactiveIcon};
    border-radius: 3px;
    padding: 1.5rem;
    transition: 0.2s all;
    height: 225px;
    @media only screen and (max-width: 991px) {
      height: 250px;
    }
    @media only screen and (max-width: 667px) {
      width: 100%;
      height: 225px;
    }
  }
  .product-tags {
    margin-bottom: 5px;
    color: ${({ theme }) => theme.colors.labelColor};
    span {
      font-size: 10px;
      line-height: 15px;
      font-weight: 700;
      text-transform: uppercase;
      &.divider {
        margin: 0 6px;
      }
      &.secondary {
        color: ${({ theme }) => theme.colors.orange};
      }
    }
  }
  .product-info {
    overflow: scroll;
    height: 100%;
    h3,
    h4 {
      color: ${({ theme }) => theme.colors.textColor};
      font-size: 20px;
      line-height: 25px;
      font-weight: 900;
      margin: 0;
      margin-bottom: 5px;
    }
    p {
      color: ${({ theme }) => theme.colors.textColor};
      margin-bottom: 10px;
      font-size: 1rem;
      line-height: 20px;
      font-weight: 300;
    }
  }

  ${({ height }) =>
    height &&
    css`
      min-height: ${height};
    `}

  &:hover {
    ${'' /* border-bottom: 3px solid ${({ theme }) => theme.colors.lightBlue}; */}
    h3,
    h4 {
      color: ${({ theme }) => theme.colors.lightBlue};
    }
    .product-info-group {
      border-bottom: 3px solid ${({ theme }) => theme.colors.lightBlue};
    }
  }
`;

export const SingleProductWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  position: relative;
  ${'' /* padding: 0 1.875rem; */}
  margin: 1rem 3rem;
  min-height: 25vh;
  z-index: 1;
  border-radius: 3px;
  border-bottom: 3px solid ${({ theme }) => theme.colors.inactiveIcon};
  background-color: ${({ theme }) => theme.colors.background};
  transition: 0.2s all;
  ${({ featured }) =>
    featured &&
    css`
      background-color: transparent;
      padding: 0;
      @media only screen and (min-width: 668px) and (max-width: 980px) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    `}
  @media only screen and (max-width: 667px) {
    margin-top: 20px;
    padding: 0 1rem;
    margin: 1rem 1rem;
  }
  @media only screen and (max-width: 572px) {
    margin-top: 20px;
    padding: 0 0rem;
    margin: 1rem 0rem;
  }
  .product-img {
    height: 85%;
    width: 30%;
    padding: 0.5rem;
    background-color: #fff;
    img {
      width: 100%;
      height: 100%;
      border-radius: 3px;
      object-fit: contain;
      max-height: 325px;
      ${'' /* box-shadow: ${({ theme }) => theme.colors.greyBoxShadow}; */}
    }
    @media only screen and (max-width: 667px) {
      padding: 0rem;
      width: 100%;
      background-color: inherit;
    }
  }
  .product-info-group {
    width: 70%;
    padding: 1.5rem;
    @media only screen and (max-width: 667px) {
      width: 100%;
    }
  }
  .product-tags {
    margin-bottom: 5px;
    color: ${({ theme }) => theme.colors.labelColor};
    span {
      font-size: 10px;
      line-height: 15px;
      font-weight: 700;
      text-transform: uppercase;
      &.divider {
        margin: 0 6px;
      }
      &.secondary {
        color: ${({ theme }) => theme.colors.orange};
      }
    }
  }
  .product-info {
    h3,
    h4 {
      color: ${({ theme }) => theme.colors.textColor};
      font-size: 20px;
      line-height: 25px;
      font-weight: 900;
      margin: 0;
      margin-bottom: 5px;
    }
    p {
      color: ${({ theme }) => theme.colors.textColor};
      margin-bottom: 10px;
      font-size: 1rem;
      line-height: 20px;
      font-weight: 300;
    }
  }

  ${({ height }) =>
    height &&
    css`
      min-height: ${height};
    `}

  &:hover {
    border-bottom: 3px solid ${({ theme }) => theme.colors.lightBlue};
    h3,
    h4 {
      color: ${({ theme }) => theme.colors.lightBlue};
    }
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: 20px;
  margin-top: 20px;
  ${({ template }) => {
    if (typeof template === 'string') {
      return css`
        grid-template-columns: ${template};
      `;
    }
    return css`
      grid-template-columns: ${template[0]};
      @media only screen and (min-width: 668px) and (max-width: 980px) {
        grid-template-columns: ${template[1]};
        grid-template-rows: ${template[1]};
      }
    `;
  }}}
  ${({ row }) =>
    row &&
    css`
      margin-top: 0px;
      grid-auto-flow: row;
    `}
  @media only screen and (max-width: 667px) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
`;

export default RelatedProductsWrapper;
